import { Bucket } from "~/api/materialize/cluster/replicaUtilizationHistory";
import { assert } from "~/util";

// Console-specific categories of a replica. Compute implies the replica has compute objects only, storage likewise, and hybrid means both.
export type ReplicaCategory = "compute" | "storage" | "hybrid" | "empty";

export type MemDiskUtilizationStatus =
  | "overProvisioned"
  | "optimal"
  | "suboptimal"
  | "underProvisioned";

export type ThresholdPercentages = {
  // We call a replica overProvisioned if < thresholdPercentages.overProvisioned
  overProvisioned: number;
  // We call a replica optimal if < thresholdPercentages.optimal
  optimal: number;
  // We call a replica optimal if < thresholdPercentages.suboptimal and underProvisioned if above
  suboptimal: number;
};

export function calculateReplicaCategory({
  numSources,
  numSinks,
  numIndexes,
  numMaterializedViews,
}: {
  numSources: number | null;
  numSinks: number | null;
  numIndexes: number | null;
  numMaterializedViews: number | null;
}) {
  const hasStorageObjects = (numSources ?? 0) > 0 || (numSinks ?? 0) > 0;
  const hasComputeObjects =
    (numIndexes ?? 0) > 0 || (numMaterializedViews ?? 0) > 0;
  const hasComputeAndStorageObjects = hasStorageObjects && hasComputeObjects;

  return hasComputeAndStorageObjects
    ? "hybrid"
    : hasStorageObjects
      ? "storage"
      : hasComputeObjects
        ? "compute"
        : "empty";
}

export function calculateMemDiskUtilizationStatus({
  thresholdPercentages,
  peakMemDiskUtilizationPercent,
}: {
  thresholdPercentages: ThresholdPercentages;
  peakMemDiskUtilizationPercent: number;
}): MemDiskUtilizationStatus {
  return peakMemDiskUtilizationPercent < thresholdPercentages.overProvisioned
    ? "overProvisioned"
    : peakMemDiskUtilizationPercent < thresholdPercentages.optimal
      ? "optimal"
      : peakMemDiskUtilizationPercent < thresholdPercentages.suboptimal
        ? "suboptimal"
        : "underProvisioned";
}

/**
 * Calculates the total bytes since percent = currentBytes / totalBytes
 */
function calculateTotalBytes({
  currentBytes,
  percent,
}: {
  currentBytes: number;
  percent: number;
}) {
  assert(percent > 0, "percent must be greater than 0");

  return currentBytes / percent;
}

/**
 *
 * Given the category of a replica and a bucket, finds the peak storage utilization percentage
 * and thresholds that determine its utilization status. If null, it implies there's no
 * utilization data in the bucket to calculate the peak storage utilization.
 */
export function calculatePeakMemDiskUtilization(params: {
  replicaCategory: ReplicaCategory;
  bucket: Bucket;
}) {
  const { replicaCategory } = params;

  let peakMemDiskUtilizationPercent = 0,
    memoryPercent = 0,
    diskPercent = 0,
    occurredAt: Date | null = null;

  const thresholdPercentages: ThresholdPercentages = {
    overProvisioned: 0.3,
    optimal: 0.7,
    suboptimal: 0.85,
  };

  switch (replicaCategory) {
    case "hybrid":
    case "compute": {
      const peakUtilization = params.bucket.maxMemoryAndDisk;
      if (!peakUtilization) {
        return null;
      }

      occurredAt = peakUtilization.occurredAt;
      const memoryBytes = peakUtilization.memoryBytes;
      const diskBytes = peakUtilization.diskBytes;

      memoryPercent = peakUtilization.memoryPercent;
      diskPercent = peakUtilization.diskPercent;

      if (memoryPercent <= 0) {
        break;
      }

      const totalMemoryBytes = calculateTotalBytes({
        currentBytes: memoryBytes,
        percent: memoryPercent,
      });

      const totalDiskBytes =
        diskBytes && diskPercent > 0
          ? calculateTotalBytes({
              currentBytes: diskBytes,
              percent: diskPercent,
            })
          : 0;

      // For hybrid and compute clusters, we use the percentage of the current disk and memory
      // relative to the total memory of a cluster. If over 100%, it means the replica is
      // spilling to disk. Too much spilling to disk is unwanted for compute clusters since it means
      // performance degradation, but it's satisfactory that a cluster will spill to disk during hydration/rehydration        .
      const maxUtilizationPercent =
        (totalMemoryBytes + totalDiskBytes) / totalMemoryBytes;
      peakMemDiskUtilizationPercent =
        (diskBytes + memoryBytes) / totalMemoryBytes;
      // Since peakMemDiskUtilizationPercent can be above 100,
      // we normalize it such that it fits on a scale from 0 to 100
      peakMemDiskUtilizationPercent =
        peakMemDiskUtilizationPercent / maxUtilizationPercent;

      // Means we haven't spilled to disk yet and only use memory
      thresholdPercentages.overProvisioned = 0.4 / maxUtilizationPercent;
      // Means we're close to spilling to disk and only use memory
      thresholdPercentages.optimal = 0.9 / maxUtilizationPercent;
      // We use 180% as our "satistfactory" threshold through analyzing different customer environments
      thresholdPercentages.suboptimal = 1.8 / maxUtilizationPercent;

      break;
    }
    default: {
      memoryPercent = params.bucket.maxMemory?.percent ?? 0;
      diskPercent = params.bucket.maxDisk?.percent ?? 0;
      if (memoryPercent > diskPercent) {
        peakMemDiskUtilizationPercent = memoryPercent;
        occurredAt = params.bucket.maxMemory?.occurredAt ?? null;
      } else {
        peakMemDiskUtilizationPercent = diskPercent;
        occurredAt = params.bucket.maxDisk?.occurredAt ?? null;
      }
      if (occurredAt === null) {
        return null;
      }
    }
  }

  return {
    peakMemDiskUtilizationPercent,
    occurredAt,
    thresholdPercentages,
    memoryPercent,
    diskPercent,
  };
}
