import {
  Button,
  Circle,
  Collapse,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import { CardFooter } from "~/components/cardComponents";
import ErrorBox from "~/components/ErrorBox";
import { Gauge } from "~/components/Gauge";
import { LoadingContainer } from "~/components/LoadingContainer";
import TextLink from "~/components/TextLink";
import { ChevronDownIcon, InfoIcon } from "~/icons";
import {
  MainContentContainer,
  PageHeader,
  PageHeading,
} from "~/layouts/BaseLayout";
import docUrls from "~/mz-doc-urls.json";
import { absoluteClusterPath } from "~/platform/routeHelpers";
import { useRegionSlug } from "~/store/environments";
import { MaterializeTheme } from "~/theme";
import { formatDate, TIME_FORMAT } from "~/utils/dateFormat";

import { useReplicaMemDiskUtilization } from "./queries";
import {
  calculateMemDiskUtilizationStatus,
  MemDiskUtilizationStatus,
  ThresholdPercentages,
} from "./utils";

const HealthBadge = ({
  color,
  children,
}: {
  color: string;
  children: React.ReactNode;
}) => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <HStack paddingX="2" borderRadius="xl">
      <Circle size="2" bg={color} />
      <Text textStyle="text-ui-med" color={colors.foreground.secondary}>
        {children}
      </Text>
    </HStack>
  );
};

const utilizationStatusToColor = (
  utilizationStatus: MemDiskUtilizationStatus,
  colors: MaterializeTheme["colors"],
) => {
  switch (utilizationStatus) {
    case "overProvisioned":
      return colors.accent.blue;
    case "optimal":
      return colors.accent.darkGreen;
    case "suboptimal":
      return colors.accent.darkYellow;
    case "underProvisioned":
      return colors.accent.red;
  }
};

const formatPercentage = (percentage: number) =>
  `${(percentage * 100).toFixed(2)}%`;

const buildReplicaTickMarks = ({
  thresholdPercentages,
  colors,
}: {
  thresholdPercentages: ThresholdPercentages;
  colors: MaterializeTheme["colors"];
}) => {
  return [
    {
      color: utilizationStatusToColor("overProvisioned", colors),
      startPercentage: 0,
      endPercentage: thresholdPercentages.overProvisioned,
    },
    {
      color: utilizationStatusToColor("optimal", colors),
      startPercentage: thresholdPercentages.overProvisioned,
      endPercentage: thresholdPercentages.optimal,
    },
    {
      color: utilizationStatusToColor("suboptimal", colors),
      startPercentage: thresholdPercentages.optimal,
      endPercentage: thresholdPercentages.suboptimal,
    },
    {
      color: utilizationStatusToColor("underProvisioned", colors),
      startPercentage: thresholdPercentages.suboptimal,
      endPercentage: 1,
    },
  ];
};

const MemDiskUtilizationDetailItem = ({
  label,
  children,
}: {
  label: React.ReactNode;
  children: React.ReactNode;
}) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <HStack gap="2" width="100%" justifyContent="space-between">
      <Text textStyle="text-base" color={colors.foreground.secondary}>
        {label}
      </Text>
      <Text textStyle="text-base" color={colors.foreground.primary}>
        {children}
      </Text>
    </HStack>
  );
};

const MemDiskUtilizationPopover = ({
  header,
  children,
}: {
  header?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <Popover trigger="hover" placement="right">
      <PopoverTrigger>
        <InfoIcon h="5" cursor="pointer" w="5" />
      </PopoverTrigger>

      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>{header}</PopoverHeader>
          <PopoverBody>{children}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const EnvironmentOverview = () => {
  const { colors, shadows } = useTheme<MaterializeTheme>();
  const {
    isLoading,
    data: clusterMemDiskUtilization,
    isError,
  } = useReplicaMemDiskUtilization();
  const regionSlug = useRegionSlug();
  const { isOpen: isLegendOpen, onToggle: onToggleLegend } = useDisclosure({
    defaultIsOpen: false,
  });

  return (
    <MainContentContainer>
      <PageHeader>
        <PageHeading>Environment Overview</PageHeading>
      </PageHeader>
      <VStack alignItems="flex-start" width="100%" height="100%">
        <VStack spacing="1" alignItems="flex-start">
          <Text textStyle="heading-md" color={colors.foreground.primary}>
            Cluster utilization
          </Text>
          <Text textStyle="text-small" color={colors.foreground.secondary}>
            The status for each cluster in your environment based on its
            resource utilization. Use the gauges below as guidance to{" "}
            <TextLink
              href={`${docUrls["/docs/concepts/clusters/"]}#sizing-your-clusters`}
              isExternal
            >
              size your clusters
            </TextLink>
            .
          </Text>
        </VStack>

        {isError ? (
          <ErrorBox />
        ) : isLoading ? (
          <LoadingContainer />
        ) : (
          <>
            <VStack alignItems="flex-start" spacing="0">
              <Button
                variant="borderless"
                size="sm"
                onClick={onToggleLegend}
                paddingLeft="0"
              >
                <HStack spacing="4">
                  <HealthBadge
                    color={utilizationStatusToColor("overProvisioned", colors)}
                  >
                    Over-provisioned
                  </HealthBadge>

                  <HealthBadge
                    color={utilizationStatusToColor("optimal", colors)}
                  >
                    Optimal
                  </HealthBadge>

                  <HealthBadge
                    color={utilizationStatusToColor("suboptimal", colors)}
                  >
                    Suboptimal
                  </HealthBadge>

                  <HealthBadge
                    color={utilizationStatusToColor("underProvisioned", colors)}
                  >
                    Under-provisioned
                  </HealthBadge>

                  <ChevronDownIcon
                    transform={isLegendOpen ? "rotate(180deg)" : undefined}
                  />
                </HStack>
              </Button>

              <Collapse in={isLegendOpen}>
                <VStack alignItems="flex-start">
                  <Text>
                    <Text
                      as="span"
                      textStyle="text-ui-med"
                      color={colors.foreground.secondary}
                    >
                      Over-provisioned:{" "}
                    </Text>
                    The cluster is underutilized and can be sized down.
                  </Text>
                  <Text>
                    <Text
                      as="span"
                      textStyle="text-ui-med"
                      color={colors.foreground.secondary}
                    >
                      Optimal:{" "}
                    </Text>
                    The cluster is optimally sized for performance.
                  </Text>
                  <Text>
                    <Text
                      as="span"
                      textStyle="text-ui-med"
                      color={colors.foreground.secondary}
                    >
                      Sub-optimal:{" "}
                    </Text>
                    Latency is negatively impacted due to memory spilling to
                    disk. Consider increasing the size of your cluster.
                  </Text>
                  <Text>
                    <Text
                      as="span"
                      textStyle="text-ui-med"
                      color={colors.foreground.secondary}
                    >
                      Under-provisioned:{" "}
                    </Text>
                    The cluster is at risk of crash looping due to running out
                    of memory and disk. Increase the size of your cluster.
                  </Text>
                </VStack>
              </Collapse>
            </VStack>

            <Stack width="100%" spacing="4" direction="row" flexWrap="wrap">
              {[...(clusterMemDiskUtilization ?? []).values()]
                .sort((a, b) => a.clusterName.localeCompare(b.clusterName))
                .map(
                  ({
                    peakMemDiskUtilizationPercent,
                    thresholdPercentages,
                    clusterId,
                    clusterName,
                    replicaName,
                    replicaId,
                    numClusterReplicas,
                    occurredAt,
                    diskPercentage,
                    memoryPercentage,
                  }) => {
                    const utilizationStatus = calculateMemDiskUtilizationStatus(
                      {
                        thresholdPercentages,
                        peakMemDiskUtilizationPercent,
                      },
                    );
                    const shouldShowPopover =
                      utilizationStatus !== "optimal" &&
                      utilizationStatus !== "overProvisioned";

                    return (
                      <VStack
                        borderRadius="lg"
                        boxShadow={shadows.level1}
                        key={replicaId}
                        alignItems="flex-start"
                        flexShrink="0"
                        width="300px"
                        spacing="0"
                      >
                        <VStack
                          padding="4"
                          alignItems="flex-start"
                          width="100%"
                        >
                          <VStack width="100%" spacing="0">
                            <Gauge
                              percentage={peakMemDiskUtilizationPercent}
                              size={100}
                              tickMarks={buildReplicaTickMarks({
                                thresholdPercentages,
                                colors,
                              })}
                            />
                            <HStack
                              marginTop="-2"
                              spacing="1"
                              width="100%"
                              justifyContent="center"
                            >
                              <Text textStyle="text-ui-med" noOfLines={1}>
                                {clusterName}
                                {(numClusterReplicas ?? 0) > 1 && (
                                  <Text as="span">.{replicaName}</Text>
                                )}
                              </Text>
                              {shouldShowPopover && (
                                <MemDiskUtilizationPopover
                                  header={
                                    <Text textStyle="text-ui-med">
                                      Resource usage high.
                                    </Text>
                                  }
                                >
                                  <>
                                    <MemDiskUtilizationDetailItem label="Occurred at">
                                      {occurredAt
                                        ? formatDate(
                                            occurredAt,
                                            `M/dd/yy ${TIME_FORMAT} z`,
                                          )
                                        : "-"}
                                    </MemDiskUtilizationDetailItem>
                                    <MemDiskUtilizationDetailItem label="Memory">
                                      {formatPercentage(memoryPercentage)}
                                    </MemDiskUtilizationDetailItem>
                                    <MemDiskUtilizationDetailItem label="Disk">
                                      {formatPercentage(diskPercentage)}
                                    </MemDiskUtilizationDetailItem>
                                  </>
                                </MemDiskUtilizationPopover>
                              )}
                            </HStack>
                          </VStack>
                        </VStack>

                        <CardFooter
                          justifyContent="flex-end"
                          borderBottomLeftRadius="lg"
                          borderBottomRightRadius="lg"
                          padding="2"
                        >
                          <Button
                            as={Link}
                            to={absoluteClusterPath(regionSlug, {
                              id: clusterId,
                              name: clusterName,
                            })}
                            background={colors.background.primary}
                            size="sm"
                            variant="outline"
                          >
                            View cluster
                          </Button>
                        </CardFooter>
                      </VStack>
                    );
                  },
                )}
            </Stack>
          </>
        )}
      </VStack>
    </MainContentContainer>
  );
};

export default EnvironmentOverview;
