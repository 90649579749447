import { useQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";

import {
  buildQueryKeyPart,
  buildRegionQueryKey,
} from "~/api/buildQueryKeySchema";
import { fetchReplicaUtilizationHistory } from "~/api/materialize/cluster/replicaUtilizationHistory";
import {
  ClusterReplicaDetails,
  fetchClusterReplicaDetails,
} from "~/api/materialize/environment-overview/clusterReplicaDetails";

import {
  calculatePeakMemDiskUtilization,
  calculateReplicaCategory,
  ReplicaCategory,
  ThresholdPercentages,
} from "./utils";

export type ClusterMemDiskUtilizationSummaryMap = Map<
  string,
  {
    peakMemDiskUtilizationPercent: number;

    thresholdPercentages: ThresholdPercentages;

    replicaId: string;
    replicaName: string;
    clusterId: string;
    clusterName: string;
    lastRestartAt?: Date;
    numClusterReplicas: number;
    replicaCategory: ReplicaCategory;

    memoryPercentage: number;
    diskPercentage: number;
    occurredAt: Date | null;
  }
>;

const environmentOverviewQueryKeys = {
  all: () => buildRegionQueryKey("environmentOverview"),
  clusterReplicaDetails: () =>
    [
      ...environmentOverviewQueryKeys.all(),
      buildQueryKeyPart("clusterReplicaDetails"),
    ] as const,

  replicaMemDiskUtilization: () =>
    [
      ...environmentOverviewQueryKeys.all(),
      buildQueryKeyPart("replicaMemDiskUtilization"),
    ] as const,
};

export function clusterReplicaDetailsSelector(
  data: Awaited<ReturnType<typeof fetchClusterReplicaDetails>>,
) {
  return data.rows.reduce((accum, row) => {
    accum.set(row.id, row);
    return accum;
  }, new Map<string, ClusterReplicaDetails>());
}

export function useClusterReplicaDetails() {
  return useQuery({
    queryKey: environmentOverviewQueryKeys.clusterReplicaDetails(),
    queryFn: ({ queryKey, signal }) => {
      return fetchClusterReplicaDetails({
        queryKey,
        requestOptions: { signal },
      });
    },
    select: clusterReplicaDetailsSelector,
  });
}
const UTILIZATION_LOOKBACK_DAYS = 15;
const DAY_IN_MS = 24 * 60 * 60 * 1000;

export function useReplicaMemDiskUtilization() {
  return useQuery({
    queryKey: environmentOverviewQueryKeys.replicaMemDiskUtilization(),
    queryFn: async ({ queryKey, signal }) => {
      const endDate = new Date();

      const startDate = subDays(endDate, UTILIZATION_LOOKBACK_DAYS);

      const [replicaDetailsRes, replicaUtilizationHistoryRes] =
        await Promise.all([
          fetchClusterReplicaDetails({
            queryKey,
            requestOptions: { signal },
          }),
          fetchReplicaUtilizationHistory({
            params: {
              bucketSizeMs: DAY_IN_MS,
              startDate: startDate.toISOString(),
            },
            queryKey,
            requestOptions: { signal },
          }),
        ]);

      const replicaDetailsById = replicaDetailsRes.rows.reduce((accum, row) => {
        accum.set(row.id, row);
        return accum;
      }, new Map<string, ClusterReplicaDetails>());

      const replicaUtilizationSummaryMap: ClusterMemDiskUtilizationSummaryMap =
        new Map();

      for (const [replicaId, replicaDetails] of replicaDetailsById.entries()) {
        const buckets =
          replicaUtilizationHistoryRes.bucketsByReplicaId[replicaId];

        if (buckets.length === 0) {
          continue;
        }

        const replicaCategory = calculateReplicaCategory({
          numSources: Number(replicaDetails.numSources),
          numSinks: Number(replicaDetails.numSinks),
          numIndexes: Number(replicaDetails.numIndexes),
          numMaterializedViews: Number(replicaDetails.numMaterializedViews),
        });

        const peakUtilizationBuckets = buckets.map((bucket) => {
          return calculatePeakMemDiskUtilization({ replicaCategory, bucket });
        });
        const maxPeakUtilizationBucket = peakUtilizationBuckets.reduce(
          (maxBucket, bucket) => {
            if (maxBucket === null || bucket === null) {
              return bucket;
            }

            return bucket.peakMemDiskUtilizationPercent >=
              maxBucket.peakMemDiskUtilizationPercent
              ? bucket
              : maxBucket;
          },
          null,
        );

        if (maxPeakUtilizationBucket === null) {
          continue;
        }

        replicaUtilizationSummaryMap.set(replicaId, {
          replicaId: replicaDetails.id,
          replicaName: replicaDetails.replicaName,
          clusterId: replicaDetails.clusterId,
          clusterName: replicaDetails.clusterName,
          numClusterReplicas: Number(replicaDetails.numClusterReplicas),
          replicaCategory,

          peakMemDiskUtilizationPercent:
            maxPeakUtilizationBucket.peakMemDiskUtilizationPercent,
          thresholdPercentages: maxPeakUtilizationBucket.thresholdPercentages,
          memoryPercentage: maxPeakUtilizationBucket.memoryPercent,
          diskPercentage: maxPeakUtilizationBucket.diskPercent,
          occurredAt: maxPeakUtilizationBucket.occurredAt,
        });
      }

      return replicaUtilizationSummaryMap;
    },
  });
}
